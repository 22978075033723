.App {
  text-align: center;
}

body {
  margin: 0;
  font-family: ui-monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1f1949;
  background-size: 80%;

}

code {
  font-family: ui-monospace;
}

/*  */

.bodyG{
  background-color: #151320;
  height: 100vh;
  display: grid;
  place-items: center center;
  font-family: 'Poppins',sans-serif;
}
.cards{
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
  padding: 20px;
  grid-gap: 40px;
}
.card{
  background-color: #1c1b29;
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(0,0,0,0.18);
}
.containerG{
  position: relative;
  clip-path: polygon(0 0,100% 0, 100% 85%, 0 100%);
}
.imgG{
  width: 100%;
  display: block;
  border-radius: 20px 20px 0 0;
}
.containerG:after{
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px 20px 0 0;
  opacity: 0.7;
}
/* .card1>.containerG:after{
  background-image: linear-gradient(
      135deg,
      #0100ec,
      #fb36f4
  );
}
.card2>.containerG:after{
  background-image: linear-gradient(
      135deg,
      #2bdce3,
      #42ffdb
  );
}
.card3>.containerG:after{
  background-image: linear-gradient(
      135deg,
      #ffab1d,
      #f8501c
  );
} */
.details{
  padding: 20px 10px;
}
.details>h3{
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0 15px 0;
}
.details>p{
  color: #a0a0a0;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
}

/*  */

.checkout {
  padding-top: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  width: 100%;
  padding: 10px;
  max-width: 1460px;
  border-bottom: 2px solid #bf2d06;
  padding-bottom: 4em;
  margin-bottom: 7em;
}

.style1 {
  max-width: 1040px;
  margin: auto;
  width: 50%;
  padding: 10px;
  margin-top: 100px;
  position: relative;
  z-index: 999999;
}

.tickets {
  justify-content: center;
  display: flex;
  font-family: ui-monospace;
}

.checkout-title {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  margin: 0;
  color: rgba(0, 0, 0, 0.7);
}

.checkout-price {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 95px;
  margin: 0;
  color: #bf2d06;
  margin-top: 1em;
  font-family: ui-monospace;
}

.checkout-description {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.7);
}

.checkout-product-image {
  width: 400px;
}

.checkout-button {

  background-color: transparent;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 20px 40px rgba(23, 0, 102, 0.2);
  border-radius: 20px;

  display: flex;

  padding: 4px;
  width: 169px;
  cursor: pointer;
  margin: 0 auto;

  
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.checkout-button:focus {
  outline: none;
}

.checkout-button:hover {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1),
    0px 30px 60px rgba(23, 0, 102, 0.3);
  /* transform: translateY(-2px); */
  transition: all 0.5s ease-in-out;
  scale: 1.1;

}

.checkout-button .grey-circle {
  background: rgba(68, 66, 178, 0.1);
  box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-button .purple-circle {
  background: linear-gradient(200.44deg, #4316db 13.57%, #9076e7 98.38%);
  box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.1);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.checkout-button .icon {
  width: 20px;
  height: 20px;
  margin: 0px;
}

.checkout-button .text-container {

  grid-template-columns: 1fr;
  grid-row-gap: 4px;
  margin: auto;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.checkout-button .text {
  font-style: normal;
  font-weight: normal;
  font-size: x-large;
  line-height: 130%;
  margin: 0;
  color: #bf2d06;
  margin: 0;
  justify-content: center;
}



.quantity-input {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-top: 2em;
}
.quantity-input:focus {
  background: red;
}

.quantity-input__modifier,
.quantity-input__screen {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
}

.quantity-input__modifier {
  padding: 0.7rem;
  width: 3rem;
  font-size: 1.5rem;
  background: #f3f3f3;
  color: #bf2d06;
  border: 1px solid #bf2d06;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  cursor: pointer;
  background-color: transparent;
}
.quantity-input__modifier:hover {
  background: black;
  color: #555555;
}
.quantity-input__modifier--left {
  border-radius: 7px 0 0 7px;
}
.quantity-input__modifier--right {
  border-radius: 0 7px 7px 0;
}

.quantity-input__screen {
  background-color: transparent;
  width: 4rem;
  padding: 0.9rem;
  font-size: 1.8rem;
  border: 0;
  border-top: 0 solid #dbdbdb;
  border-bottom: 0 solid #dbdbdb;
  text-align: center;
}

.checkoutLeft {
  width: 50%; 
  float:left;
}
.checkoutRight {
  width: 50%; 
  float:right;
}

.ticket {
  float: left;
  margin-top: 6em;
  width: 90%;
  height: auto;
}

.center {
  justify-content: center;
  align-items: center;
}

.right {
  display: inline-block;
  margin-right: 1em;
  color: wheat;
  border: transparent;
}
.right1 {
  position: absolute;
  right: 0px;
  display: inline-block;
  color: wheat;
  font-size: large;
}
.left {
  display: inline-block;
  margin-left: 1em;
  color: wheat;
}
.left1 {
  position: absolute;
  left: 0px;
  display: inline-block;
  color: wheat;
  font-size: large;
}

.vercent {
  display: flex;
  align-items: center;
}


.image-135 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  /* width: 100%; */
  /* height: 60%; */
  border-radius: 2em;
  -o-object-fit: cover;
  object-fit: cover;
  /* height: 50%; */
  /* width: 100%; */
  /* max-width: 1460px; */
  width: 1px;
  height: 1px;
  display: initial;
  /* height: 50%;
  max-height: 500px; */
  /* margin-top: 4em; */
  border-bottom: 2px solid #bf2d06;
  padding-top: 1.5em;
}

.borderio {
  padding-top: 10em;
  border-bottom: 2px solid #bf2d06;
  padding-bottom: 2.5em;
}

.buybuttontop {
  width: 4%;
    display: inline-block;
    margin-right: 5%;
    margin-left: 5%;
}
.buybuttontop2 {

    display: inline-block;

}

.buttonwidth {
  width: 20em;
}

.accountLeft {
  width: 50%;
  float: left;
  background-image: url('./images/vector1.png');
  min-height: 100%;
  background-size: cover;
  background-position: right;
  border-radius: 4em;
  justify-content: center;
  display: flex;
  align-items: center;
}

.accountRight {
  width: 50%;
  float: right;
  /* background-color: wheat; */
  min-height: 100%;
  border-radius: 0 4em 4em 0;
  color: #252525;
  align-items: center;
  display: flex;
  visibility: visible;
}

.insideLeft {
  border-radius: 2em;
  width: 70%;
  height: 57vh;
  border: solid 1px #252525;
  backdrop-filter: blur(8px);  
}

.insideLefttext {
  margin-top: 6vh;
    justify-content: flex-start;
    display: flex;
    margin-left: 2vh;
    font-size: xx-large;
    color: wheat;
    font-weight: 700;
}
.insideLefttext2 {
  margin-top: 1vh;
    justify-content: flex-start;
    display: flex;
    margin-left: 2vh;
    font-size: small;
    color: rgb(184, 176, 160);
    font-weight: 900;
  text-align: left;
  max-width: 70%;
}


.auth {
  margin: 3rem auto;
  width: 95%;
  max-width: 35rem;
  border-radius: 6px;
  background-color: transparent;
  /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
  padding: 1rem;
  text-align: center;
  color: #252525;
}

.auth h1 {
  text-align: center;
  color: #252525;
  margin-bottom: 7vh;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  color: #252525;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.control input {

  background-color: wheat;
  color: #252525;
  border-radius: 0.9em;
  border: 1px solid #252525;
  width: 100%;
  text-align: left;
  padding: 10px;
}

.controlE {
  margin-bottom: 0.5rem;
}

.controlE label {
  display: block;
  color: #252525;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.controlE input {

  background-color: wheat;
  color: #252525;
  border-radius: 0.9em;
  border: 1px solid #252525;
  width: 100%;
  text-align: left;
  padding: 10px;
}

.sendinfo {
  font-weight: 900;
  font-size: xx-large;
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions button {
  cursor: pointer;
  width: 100%;
  /* font: inherit; */
  color: wheat;
  background-color: #252525;
  border: none;
  border-radius: 0.9em;
  padding: 0.5rem 2.5rem;
}

.actions button:hover {

  /* background-color: rgb(132, 13, 13);
  border-color: rgb(132, 13, 13); */
}

.actions .toggle {
  margin-top: 1rem;
  background-color: transparent;
  color: #252525;
  border: none;
  padding: 0.15rem 1.5rem;
  border-bottom: solid 1px #252525;
  border-radius: 0;
}

.actions .toggle:hover {
  /* background-color: transparent;
  border: solid 2px rgb(132, 13, 13);
  color: #252525; */
}

.hideonmobile {
  visibility: visible;
}
.hiddenOnDesctop {
  visibility: hidden;
}

.text11 {
  height: 100%;
  background-image: url("./images/logoOpac.png");
  background-position: center;
  background-size: 51em;
  padding: 9em;
  background-repeat: no-repeat;
  margin: auto;
  max-width: 80%;
  text-align: center;
}

.text12 {
  font-weight: bold;
  margin-top: 3vh;
  color: wheat;
  font-size: 2vh;
  letter-spacing: 9px;
}
.text21 {
  height: 100%;
  background-image: url("./images/columns.png");
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  max-width: 1460px;
  background-size: 100%;
  padding: 19em 8em 19em 8em;
}

.text22 {
  font-weight: bold;
  margin-top: 3vh;
  color: wheat;
  font-size: 2vh;
  letter-spacing: 9px;
}
.text31 {
  border-bottom: 2px solid #bf2d06;
  height: 100%;
  background-image: url("./images/leaf.png");
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  max-width: 1460px;
  background-size: 100%;
  padding: 19em 8em 19em 8em;
}
.text312 {

  height: 100%;
  background-image: url("./images/logoOpac.png");
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  max-width: 1460px;
  background-size: 60%;
  padding: 10em 6em;
}

.text32 {
  font-weight: bold;
  margin-top: 3vh;
  color: wheat;
  font-size: 2vh;
  letter-spacing: 9px;
}

.image-136 {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  width: 100%;
  height: 60%;
  border-radius: 2em;
  -o-object-fit: cover;
  object-fit: cover;
  height: 50%;
  width: 100%;
  max-width: 1460px;
  display: initial;
  height: 50%;
  max-height: 500px;
  margin-top: 4em;
  border-bottom: 2px solid #bf2d06;
  padding-top: 1.5em;

}

.profile1 {
  width: 100%;
  top: 10%;
  max-width: 1460px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: flex-start;
  /* margin-top: -13%; */

}

.profile0 {
  background-color: rgb(132 13 13);
  max-width: 1460px;
  width: 100%;
  height: 35vh;
  justify-content: center;
  display: flex;
  margin: auto;
  border-radius: 4em;
  
}
.profile01 {
  background-image: url("./images/leaf.png");
  max-width: 1460px;
  width: 100%;
  height: 35vh;
  justify-content: center;
  display: flex;
  margin: auto;
  border-radius: 4em;
  justify-content: flex-start;
}

.profile2 {
  top: 10%;
  max-width: 1460px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

}

.profileImage2 {
  scale: 0.6;
  transform: translateY(13%);
  border-radius: 100%;
  border: 0.5em solid black;
}

.profileImage2Img {
  scale: 0.6;
  transform: translateY(13%);
  border-radius: 100%;
  border: 0.5em solid black;
}

.profileImage1 {
  width: 35%;
}

.profileText1 {
  max-width: 50vh;
  font-size: 5vh;
  font-family: fantasy;
  color: wheat;
}

.profile00{
  margin: 0;
}

body {
  font-family: "Poppins", sans-serif;
}

.containerH {
  position: fixed;
  max-width: 1050px;
  /* width: 90%; */
  margin: auto;
  width: 100%;
  background-image: url("./images/bg.png");
  height: 100%;
  z-index: 9999999999999999999999999999999999999999;
  border-bottom: 0.5em solid #bf2d06;

}

.navbar {
  width: 100%;
  /* box-shadow: 0 1px 4px rgb(146 161 176 / 15%); */

  position: fixed;
  background-image: url("./images/bg.png");
  display: none;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
}

.navbar .menu-items {
  display: flex;

}

.navbar .nav-container li {
  list-style: none;
}

.navbar .nav-container a {
  text-decoration: none;
  color: wheat;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0.7rem;
}

.navbar .nav-container a:hover{
    font-weight: bolder;
}

.nav-container {
  display: block;
  position: relative;
  height: 60px;
}

.nav-container .checkbox {
  position: fixed;
  display: block;
  height: 32px;
  width: 32px;
  top: 20px;
  left: 20px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
  z-index: 99999999999;
}

.nav-container .hamburger-lines {
  display: block;
  height: 26px;
  width: 32px;
  position: fixed;
  top: 17px;
  left: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99999999;
}

.nav-container .hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: wheat;
}

.nav-container .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.navbar .menu-items {
  padding-top: 120px;
  background-image: url("./images/bg.png");
  height: 100vh;
  /* width: 110%; */
  transform: translate(-200%);
  display: flex;
  flex-direction: column;
  /* margin-left: -40px; */
  /* padding-left: 50px; */
  transition: transform 0.5s ease-in-out;
  text-align: center;
  position: fixed;
  z-index: 999999;
  /* transform: translateY(100%); */
  width: 100%;
}

.navbar .menu-items li {
  margin-bottom: 1.2rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.logo {
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 1.2rem;
  color: wheat;
}

.nav-container input[type="checkbox"]:checked ~ .menu-items {
  transform: translateX(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: rotate(45deg);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

.nav-container input[type="checkbox"]:checked ~ .logo{
  display: none;
}

.cookie {
  width: 65%;
  margin-top: 5vh;
}
.resetpassio {
  text-transform: lowercase;
  margin-top: 2vh;
  font-size: 2vh;
  color: rgb(33, 33, 33);
  letter-spacing: 1px;
}
.profileStyle{
  width: 35%;
}

@media screen and (max-width: 991px) {
  .profileText1{
    font-size: 5vh;
  }
  .navbar{
    display: block;
  }
  .image-135 {
    padding: 3em;
    border-radius: 5em;
    border-bottom: 0;
  }

  .center {
    display: flex;
    justify-content: flex-end;
  }

  .checkout-price{
    font-size: xx-large;
  }

  .insideLeft {
    height: 45vh;
  }

  .profile01{
    justify-content: center;
  }
  .profile0 {
    display: flex;
    justify-content: center;
    /* margin-left: 5em;
    margin-right: 5em; */
  }

  .profileImage2 {
    max-width: 100%;
    transform: translateY(0);
  }
  .profileImage2Img {
    scale: 1;
    max-width: 100%;
    transform: translateY(0);
    margin-top: 20vh;
  }

  .profile00 {
    margin-left: 2em;
    margin-right: 2em;
  }

}

@media screen and (max-width: 767px) {

  .insideLefttext {
    text-align: left;
  }

  .insideLefttext2 {
    font-size: smaller;
  }

  .image-135 {
    padding: 3em;
    border-radius: 5em;
    border-bottom: 0;
  }

  .center {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 479px) {
  .profileStyle{
    width: 55%;
  }
  .cookie {
    width: 90%;
  }
  .resetpassio {
    margin-top: 2vh;
    color: wheat;
  }
  .profileText1 {
    font-size: 5vh;
  }
  .insideLeft {
    border-radius: none;
    width: auto;
    height: auto;
    border: none;
    backdrop-filter: none;  
  }

  .auth {
    max-width: 50rem;
    background-color: transparent;
    padding: 4rem;
    text-align: center;
    border-radius: 2em;
    width: 100%;
    border: solid 1px #252525;
    backdrop-filter: blur(8px);
  }

  .text11 {
    display: inline-table;
    padding: 0;
  }
  .text12 {
    display: table;
    font-size: 1.7vh;
  }
  .text21 {
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .text22 {
    display: table;
    /* font-size: 0.8em; */
    scale: 0.55;
    text-align: justify;
    /* font-size: large; */
  }
  .text31 {
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .text32 {
    display: table;
    font-size: 1.1vh;
  }

  .actions .toggle {
    font-size: initial;
    color: wheat;
    margin-top: 0;
  }

  .auth {
    color: wheat;
    margin: auto;
    margin-top: 30%;
  }
  
  .auth h1 {
    color: wheat;
    margin-bottom: 1vh;

  }

  .control label {
    color: wheat;
    font-size: small;
  }
  .controlE label {
    color: wheat;
    font-size: small;
  }

  .accountRight {
    display: table;
    position: fixed;
    width: 90%;
  }

  .accountLeft {
    width: 100%;
    min-height: 100vh;
  }

  .hideonmobile {
    visibility: hidden;
    display: none;
  }

  .hiddenOnDesctop {
    visibility: visible;
  }

  .checkoutLeft {
    padding-bottom: 41em;
    justify-content: center;
    display: flex;
    position: absolute;
    max-width: 80%;
    margin-top: 13em;
  }
  .ticket {
    width: 100%;
  }
  .checkoutRight{
    justify-content: center;
    display: grid;
    z-index: 10;
  }

  .centerPass {
    justify-content: center;
    display: flex;
  }
  .buttonwidth {
    width: 70%;
  }
}

@media screen and (max-height: 810px) {
  h1 {
    font-size: 5.5em;
  }
}

@media screen and (max-width: 479px) {
  .text22 {
    scale: 0.45;
  }
  .text32 {
    font-size: 0.8vh;
  }
}